import Layout from '../components/layout/layout';
import * as layoutStyles from '../components/layout/layout.module.css';
import * as styles from './404.module.css';

const NotFoundPage = () => (
    <Layout
        title=""
    >
        <div className={layoutStyles.restricted}>
            <div className={styles.textAndImage}>
                <img src='/images/lego_worker_1.png' alt="lego_worker_1" />
                <img src='/images/lego_worker_2.png' alt="lego_worker_2" />
                <div className={styles.text}>
                    <h1>404</h1>
                    <p>Hi there, it seems you have wrong link!</p>
                    <p>This part of IX is not done yet. </p>
                </div>
            </div>
            <div className={layoutStyles.bottomSection}></div>
        </div>
    </Layout>
);

export default NotFoundPage;
